import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { registerLicense } from '@syncfusion/ej2-base';


// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

ReactDOM.createRoot(document.getElementById("root")).render(
    <App />
);


// const container = document.getElementById("root");
// const root = ReactDOM.createRoot(container);

// root.render(<App />);
